import { defineStore } from 'pinia';

export const useNoticeStore = defineStore('notices', {
  state: () => {
    return {
      errors: {},
      notice: {},
      active: [],
      notices: [],
      pagination: {},
    };
  },

  actions: {
    async index (params) {
      if (params && params.includes('filter')) {
        return await this.axios.get(`/notices?${params}`).then((res) => {
          this.notices = res.data.data;
        });
      } else {
        return await this.axios.get('/notices').then((res) => {
          this.notices = res.data.data;
        });
      }
      // return await this.axios.get('/notices').then((res) => {
      //   this.notices = res.data.data;
      // });
    },
    async show (id) {
      this.errors = {};
      this.notice = {};
      return this.axios.get(`/notices/${id}`).then((res) => {
        this.notice = res.data.data.attributes;
        return res.data.data.attributes;
      });
    },
    async new () {
      this.errors = {};
      this.notice = {};
      return this.axios.get(`/notices/new`).then((res) => {
        this.notice = res.data.data.attributes;
      });
    },
    async create () {
      this.errors = {};
      return this.axios
        .post(`/notices`, this.notice)
        .then((res) => {
          this.notice = res.data.data.attributes;
          return true;
        })
        .catch((error) => {
          this.errors = error.res.data.errors;
          return false;
        });
    },
    async edit (id) {
      this.errors = {};
      this.notice = {};
      return this.axios.get(`/notices/${id}/edit`).then((res) => {
        this.notice = res.data.data.attributes;
      });
    },
    async update (id) {
      this.errors = {};
      console.log(this.notice);
      return this.axios
        .put(`/notices/${id}`, this.notice)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async destroy (id) {
      this.errors = {};
      return this.axios
        .delete(`/notices/${id}`)
        .then((res) => {
          const i = this.notices.findIndex((notice) => notice.id === res.data.data.id);
          if (i > -1) this.notices.splice(i, 1);
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
  },
});
